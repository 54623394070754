@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Urbanist';
  scroll-behavior: smooth;
}

.scrolled {
  @apply bg-white transition ease-linear duration-200 fixed top-0 right-0 left-0 z-20;
}

.called {
  @apply flex items-center bg-[#DBFFDF] rounded-[4px] px-5 py-1;
}

.calledText {
  @apply text-primary font-medium text-sm;
}

.missed {
  @apply flex items-center bg-[#FFF3CE] rounded-[4px] px-5 py-1;
}

.missedText {
  @apply text-[#D5A407] font-medium text-sm;
}

.notcalled {
  @apply flex items-center bg-[#FDDED4] rounded-[4px] px-5 py-1;
}

.notcalledText {
  @apply text-[#B92043] font-medium text-sm;
}

.pending {
  @apply flex items-center bg-[#C9F7FC] rounded-[4px] px-5 py-1;
}

.pendingText {
  @apply text-[#0066AF] font-medium text-sm;
}

.noitem {
  @apply flex items-center bg-[#ECEEEC] rounded-[4px] px-5 py-1;
}

.noitemText {
  @apply text-[#96A397] font-medium text-sm;
}

/* react select custom styles */
.select_style .select_control__control {
  border-radius: 8px;
  font-size: 14px;
  background: #fff;
  border: 1px solid #96a397;
  color: #333333;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-height: 48px;
  width: 100%;
}

.select_style .select_control__control:hover {
  border: 1px solid #abb6ac !important;
  box-shadow: none !important;
}

.select_control__menu .select_control__menu-list .select_control__option {
  cursor: pointer;
  font-size: 14px;
  color: #333333;
}

.select_control__indicator-separator {
  display: none !important;
}

.select_control__multi-value {
  background-color: #dbffdf !important;
  border-radius: 4px !important;
}
.select_control__multi-value__label {
  color: #2b8c34 !important;
  font-weight: 500 !important;
}

.select_control__multi-value__remove {
  color: #2b8c34 !important;
}

.select_control__multi-value__remove:hover {
  background-color: #a7eaad !important;
}

.product .ctaView {
  display: none;
}
.product:hover .ctaView {
  display: block;
}

.notification_scroll_div::-webkit-scrollbar {
  width: 0px;
}
